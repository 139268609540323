import { createApp } from 'vue'
import { Router } from 'vue-router'

import { iamPredicates } from '@ankor-io/common/auth/client/predicates'
import '@ankor-io/common/tailwindcss/colours.css'
import { sentryVueInit } from '@ankor-io/sentry-conflux/src/sentry'

import App from '@/App.vue'
import { fetchConfig } from '@/config/fetch'
import { createAuthentication } from '@/iam/authentication'
import { AuthenticationContext } from '@/iam/types'
import createRouter from '@/router'
import '@/style.css'

//
// First load the config
const config = await fetchConfig()
const router: Router = createRouter()

const app = sentryVueInit(createApp(App), config.SENTRY)

//
const authenticationContext: AuthenticationContext = await createAuthentication(config.AUTHENTICATION_CLIENT_OPTIONS)

router.beforeEach(async (to) =>
  iamPredicates(authenticationContext, config.AUTHENTICATION_CLIENT_OPTIONS).beforeEach(to),
)

app.use(config)
app.use(router)

app.use(authenticationContext)

app.mount('#app')
